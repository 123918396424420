import React from "react"
import { graphql } from 'gatsby'
import Layout from "Containers/Layout/Layout"
import DataList from "Sections/DataList/DataList.jsx"
import SEO from "Src/components/seo";

const Country = props => {
  const { pageContext, data } = props

  return (
    <Layout>
      <SEO title={pageContext.title} />
      <div className="data-list-wrap mt-4">
        <div className="row">
          <div className="col d-none d-md-block">
            <span className="legend-text text-s">Country</span>
          </div>
          <div className="col">
            <span className="legend-text text-s">Year</span>
          </div>
          <div className="col">
            <span className="legend-text text-s">
              Area of Glaciers in
              km
              <sup>2</sup>
            </span>
          </div>
          <div className="col">
            <span className="legend-text text-s">Median Annual in °C</span>
          </div>
        </div>
        <div className="d-block">
          {data.allCountriesData.edges.map(item => (
            <DataList key={item.node.title} countryData={item.node} />
          ))}
        </div>
      </div>
    </Layout>
  )
}


export default Country

export const query = graphql`
  query countriesDataPageQuery {
    allCountriesData: allCountriesDataJson {
      edges {
        node {
          id
          title
          dataset {
            primary {
              area {
                end
                mid
                start
              }
              temperature {
                end
                mid
                start
              }
            }
          }
        }
      }
    }
  }
`
