import React from "react"
import "./DataList.scss"

const DataList = (props) => {
  const { countryData } = props


  const { title, dataset } = countryData
  const {area, temperature} = dataset.primary

  return (
    <div className="data-list-container mb-2">
      <div className="row mt-2">
        <div className="col-md mb-2">
          <h2 className="mt-1">{title}</h2>
        </div>
        {dataset && (
          <div className="col">
            <p>1995</p>
            <br />
            <p>2020</p>
            <br />
            <p>2050</p>
          </div>
        )}
        {area && (
          <div className="col">
            {area.start.length > 0 && (
              <p>
                <span>{area.start}</span>
              </p>
            ) || (<p>N/A</p>)}
            <br />
            {area.mid.length > 0 && (
              <p>
                <span>{area.mid}</span>
              </p>
            ) || (<p>N/A</p>)}
            <br />
            {area.end.length > 0 && (
              <p>
                <span>{area.end}</span>
              </p>
            ) || (<p>N/A</p>)}
          </div>
        )}
        {temperature && (
          <div className="col">
            {temperature.start.length > 0 && (
              <p>
                <span>{temperature.start}</span>
              </p>
            ) || (<p>N/A</p>)}
            <br />
            {temperature.mid.length > 0 && (
              <p>
                <span>{temperature.mid}</span>
              </p>
            ) || (<p>N/A</p>)}
            <br />
            {temperature.end.length > 0 && (
              <p>
                <span>{temperature.end}</span>
              </p>
            ) || (<p>N/A</p>)}
          </div>
        )}
      </div>
    </div>
  )
}

export default DataList
